<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-row class="justify-content-center">
				<b-col md="8">
					<b-card-group class="main-card">
						<b-card no-body class="py-5 d-md-down-none left-card">
							<b-card-body class="text-center">
								<div class="circle">
									<div>
										<img src="ayun-logo-with-text.svg" class="img-logo" alt="Ayun! Logo" />
									</div>
								</div>
								<p class="tagline">
									You move, and we track it.
								</p>
							</b-card-body>
						</b-card>
						<b-card no-body class="p-4 card-background right-card">
							<b-card-body>
								<div class="d-lg-none img-logo-small">
									<img src="img/logo.svg" class="ayun-logo-small" alt="Ayun! Logo" />
								</div>

								<b-form @submit.prevent="login" novalidate v-show="!has2WayAuth">
									<h2 class="title">Login</h2>
									<p class="text-muted">
										Enter your credentials here
									</p>

									<b-input-group class="mb-4 input-with-validation">
										<b-input-group-prepend>
											<b-input-group-text class="box">
												<span class="icon">
													<i class="icon-envelope"></i>
												</span>
											</b-input-group-text>
										</b-input-group-prepend>
										<input type="email" name="Email" v-model="email" v-validate="'required|email'"
											class="form-control" placeholder="Email" autocomplete="off" />
									</b-input-group>
									<span v-if="errors.has('Email')" class="help-block">{{
										errors.first('Email')
									}}</span>

									<b-input-group class="mb-4 input-with-validation">
										<b-input-group-prepend>
											<b-input-group-text class="box">
												<span class="icon">
													<i class="icon-lock"></i>
												</span>
											</b-input-group-text>
										</b-input-group-prepend>
										<input name="Password" v-model="password" v-validate="{
											required: true,
											regex: passwordRegex,
										}" class="form-control" placeholder="Password" :type="showPassword ? 'text' : 'password'"
											autocomplete="off" />
										<b-input-group-append>
											<b-button class="append-box" @click="showPassword = !showPassword">
												<span>
													<b-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
												</span>
											</b-button>
										</b-input-group-append>
									</b-input-group>
									<span v-if="errors.has('Password')" class="help-block">{{ passwordErrorMsg }} &nbsp;
									</span>

									<b-row class="input-with-validation">
										<b-col sm="12">
											<b-button block hidden id="recaptcha-login" type="button" variant="primary"
												class="px-4" :disabled="disableConfirmButtons" ref="recaptchaLogin" />
											<b-button block id="btn-login" class="login" @click="validateBeforeLogin"
												:disabled="disableConfirmButtons">
												Login
											</b-button>
										</b-col>
									</b-row>

									<b-row>
										<b-col sm="12">
											<b-button variant="link" class="link" v-b-modal.forgot-password
												:disabled="disableConfirmButtons">
												Forgot password?
											</b-button>
											<br />
											<b-link to="/privacy-policy" target="_blank" class="link">
												Privacy Policy
											</b-link>
											<p class="deployment">
												<b-badge variant="primary">{{ deployment }}</b-badge>
											</p>
										</b-col>
										<b-col class="powered-by">
											<p>Powered By
												<a href="https://tawitech.ph/" target="_blank"><img
														src="img/logo_tawitech.png" width="auto" height="25"
														alt="TAWItech Logo" /></a>
											</p>
										</b-col>										
									</b-row>
								</b-form>

								<b-form @submit.prevent="submitCode" novalidate v-show="has2WayAuth">
									<h1 class="title">Verify</h1>
									<p class="text-muted">
										The verification code was sent to your
										phone number (
										{{ phoneNumberHint }}) via text message.
									</p>

									<b-input-group class="mt-3 mb-4 input-with-validation">
										<b-input-group-prepend>
											<b-input-group-text class="box">
												<span class="icon">
													<i class="icon-key"></i>
												</span>
											</b-input-group-text>
										</b-input-group-prepend>
										<input type="number" name="Code" v-model="code" v-validate="{
											required: has2WayAuth,
											regex: '^[0-9]{6}$',
										}" maxlength="6" class="form-control" placeholder="Code" />
									</b-input-group>
									<span v-if="errors.has('Code')" class="help-block">{{
										'Enter 6-digit code sent to your phone.'
									}}</span>

									<b-row class="mt-3 input-with-validation">
										<b-col sm="12">
											<b-button type="submit" variant="primary" class="login"
												:disabled="disableConfirmButtons">
												Submit
											</b-button>
											<br />
											<b-button variant="link" class="px-0" id="recaptcha-resend-code"
												@click="sendSMSCode" :disabled="disableConfirmButtons ||
													isCounterRunning
													">
												{{ resendCodeDisplay }}
											</b-button>
											<br />
											<b-link class="link" @click="resetLogin">
												Reset Login
											</b-link>
										</b-col>
									</b-row>
								</b-form>
							</b-card-body>
						</b-card>
					</b-card-group>
				</b-col>
			</b-row>
		</div>

		<forgot-password />
		<expired-password />
		<ForceLogout :email="email" />
	</div>
</template>

<script>
// Components
import ForceLogout from '@/views/account/ForceLogout';
import ForgotPassword from '@/views/account/ForgotPassword';
import ExpiredPassword from '@/views/account/ExpiredPassword';

// Utils
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import authApi from '@/api/authApi';
import userApi from '@/api/userApi';
import userSessionApi from '@/api/userSessionApi';

// Others
import { firebase, remoteConfig } from '@/config/firebase';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'Login',
	components: {
		ForgotPassword,
		Loading,
		ForceLogout,
		ExpiredPassword,
	},
	data() {
		return {
			email: '',
			password: '',
			code: '',
			deployment: '',

			// Check for loader
			isLoading: false,
			showPassword: false,
			currUser: {},
			currCompany: {},

			has2WayAuth: false,
			resolver: {},

			recaptchaVerifier: {},
			recaptchaVerifierResendCode: {},
			recaptchaResponse: {},
			verificationId: '',
			phoneNumberHint: '',

			countDown: 120,
			isCodeExpired: false,
			isCounterRunning: false,
			resendCodeDisplay: 'Resend Code?',
			phoneAuthProvider: new firebase.auth.PhoneAuthProvider(),
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		},
	},
	async mounted() {
		this.deployment = await this.getDeployment();

		// Reset values
		this.has2WayAuth = false;
		this.verificationId = '';
		this.phoneNumberHint = '';

		this.loadLoginRecaptcha();
	},
	watch: {
		countDown() {
			this.updateResendCodeDisplay();
		},
	},
	methods: {
		loadLoginRecaptcha() {
			let vm = this;
			if (_.isEmpty(this.recaptchaVerifier)) {
				this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
					'recaptcha-login',
					{
						size: 'invisible',
						callback: () => {
							vm.onSolvedRecaptcha('recaptcha-login');
						},
					}
				);

				// Pre-render the reCAPTCHA
				this.recaptchaVerifier.render().then((widgetId) => {
					window.recaptchaWidgetId = widgetId;
				});
			}
		},
		loadResendCodeRecaptcha() {
			let vm = this;
			if (_.isEmpty(this.recaptchaVerifierResendCode)) {
				this.recaptchaVerifierResendCode =
					new firebase.auth.RecaptchaVerifier(
						'recaptcha-resend-code',
						{
							size: 'invisible',
							callback: () => {
								vm.onSolvedRecaptcha('recaptcha-resend-code');
							},
						}
					);

				// Pre-render the reCAPTCHA
				this.recaptchaVerifierResendCode.render().then((widgetId) => {
					window.recaptchaWidgetId = widgetId;
				});
			}
		},
		updateResendCodeDisplay() {
			this.resendCodeDisplay = 'Resend Code?';
			if (this.isCounterRunning) {
				this.resendCodeDisplay =
					'Code will expire at ' + this.countDown + ' (s)';
			} else if (this.isCodeExpired) {
				this.resendCodeDisplay = 'Code expired. Resend Code?';
			}
		},
		onSolvedRecaptcha(fromScreen) {
			if (fromScreen === 'recaptcha-resend-code' && this.has2WayAuth) {
				// resend sms code
				this.sendSMSCode(this.recaptchaVerifierResendCode);
			} else {
				// login
				this.login();
			}
		},

		processFormValues() {
			// Removes excess whitespace
			this.email = ValidationUtil.removeExcessWhiteSpace(this.email);
		},
		async validateBeforeLogin() {
			this.processFormValues();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}

			const { data } = await userApi.validateLogin(
				this.email,
				this.password
			);

			if (!data.isSuccess) {
				isValid = data.isSuccess;
				this.$toaster.warning(data.message);

				// Reset password when the account is locked
				if (data.isAccountLocked) {
					this.resetPassword();
				} else if (data.isPasswordExpired) {
					this.$bvModal.show('expired-password');
				}
				return;
			}

			this.currUser = data.user;
			this.currCompany = data.company;

			this.$refs.recaptchaLogin.click();
		},
		async resetPassword() {
			try {
				await firebase.auth().sendPasswordResetEmail(this.email);
			} catch (_error) {
				this.$toaster.error('Error sending reset password email. Please try again.');
			}
		},

		resetLogin(resetFields) {
			// reset form fields
			if (resetFields) {
				this.email = '';
				this.password = '';
			}
			this.code = '';
			this.has2WayAuth = false;
			this.isLoading = false;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
			this.recaptchaVerifier.reset();
		},

		async login() {
			try {
				this.isLoading = true;

				await firebase
					.auth()
					.signInWithEmailAndPassword(this.email, this.password);

				await this.redirectToDashboard(this.currUser, this.currCompany);
			} catch (error) {
				if (error.code === 'auth/wrong-password' || error.code === 'auth/internal-error') {
					// if error code is wrong password, increment login attempt
					await this.setAttempts(error.code);
					this.$toaster.warning("Invalid login credentials.");
					this.resetLogin(false);
					return;
				} else if (error.code === 'auth/multi-factor-auth-required') {
					// The user is a multi-factor user. Second factor challenge is required.
					this.resolver = error.resolver;
					this.sendSMSCode(this.recaptchaVerifier);
					return;
				} else {
					this.$toaster.warning(error.message);
					this.resetLogin(false);
					return;
				}
			}
		},
		async setAttempts() {
			let userAttempts = {
				emailAddress: this.email,
				deviceType: 'web',
			};
			//call api to set attempts
			await userSessionApi.setUserSessionAttempts(userAttempts);
		},

		sendSMSCode(recaptchaVerifier) {
			let multiFactorHint = this.resolver.hints[0];

			// for display
			this.phoneNumberHint = multiFactorHint.phoneNumber;

			let phoneInfoOptions = {
				multiFactorHint: multiFactorHint,
				session: this.resolver.session,
			};

			// Send SMS verification code
			return this.phoneAuthProvider
				.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
				.then((verificationId) => {
					this.verificationId = verificationId;

					this.isLoading = false;
					this.$toaster.success(`6-digit code was sent to your phone number ${this.phoneNumberHint}`);

					this.countDown = 120;
					this.isCounterRunning = true;
					this.isCodeExpired = false;
					this.has2WayAuth = true;

					this.startCounter();
					this.loadResendCodeRecaptcha();

					return verificationId;
				})
				.catch((error) => {
					this.$toaster.warning('Error verifying code. Please try again.');

					this.isLoading = false;

					this.countDown = 0;
					this.isCounterRunning = false;
					this.isCodeExpired = false;
					this.has2WayAuth = false;

					return error;
				});
		},
		startCounter() {
			if (this.countDown > 0) {
				setTimeout(() => {
					this.countDown -= 1;
					this.startCounter();
				}, 1000);
			} else {
				this.isCounterRunning = false;
				this.isCodeExpired = true;
			}
		},

		async submitCode() {
			if (this.isCodeExpired) {
				this.$toaster.warning(`Please resend new code to verify.`);
				return;
			}

			// show loading indicator
			this.isLoading = true;

			if (this.code.length !== 6) {
				this.$toaster.warning('Invalid code format. Please input the 6-digit code sent to your phone.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			try {
				// Ask user for the SMS verification code.
				let cred = firebase.auth.PhoneAuthProvider.credential(
					this.verificationId,
					this.code
				);
				let multiFactorAssertion =
					firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

				await this.resolver.resolveSignIn(multiFactorAssertion);
				await this.redirectToDashboard(this.currUser, this.currCompany);
			} catch (_error) {
				// hide loading indicator
				this.isLoading = false;
				this.recaptchaVerifierResendCode.reset();
				this.$toaster.error('Error submitting code. Please try again.');
			}
		},

		async redirectToDashboard(currUser, currCompany) {
			// initialize the token after successful login
			await this.$store.dispatch('authenticateUser', {
				email: this.email,
				password: this.password,
			});

			// log the user details to session
			await this.$store.dispatch('logUserCredentials', {
				currUser: currUser,
				currCompany: currCompany,
			});

			// redirect to proper dashboard screen
			if (UserUtil.isSuperAdmin(currUser.emailAddress)) {
				await this.getInitUserData(currUser.id, config.view.ADMIN);
				this.showValidationMsg('Login successful.', true);
				this.$router.push('/admin/dashboard');
			} else {
				await this.getInitUserData(currUser.id, config.view.COMPANY);
				this.showValidationMsg('Login successful.', true);
				this.$router.push('/dashboard');
			}
		},
		async getInitUserData(userId, view) {
			let { data } = await authApi.getUserInitData(userId, view);

			this.$store.dispatch('setAllCompanies', data.companies);
			this.$store.dispatch('setAllStorageLocations', data.storageLocations);
			this.$store.dispatch('setAllConnections', data.connections);
			this.$store.dispatch('setAllUsers', data.users);
			this.$store.dispatch('setAllAssetTypes', data.assetTypes);
			this.$store.dispatch('setAllAssetTypeCategories', data.assetTypeCategories);
			this.$store.dispatch('setAllUoms', data.uoms);

			if (view === config.view.COMPANY) {
				this.$store.dispatch('setAllConnectedCompanies', data.connectedCompanies);
				this.$store.dispatch('setAllConnectedStorageLocations', data.connectedStorageLocations);
				this.$store.dispatch('setAllConnectedUsers', data.connectedUsers);
			}

			// w/ Dispatch and Transport Permission
			this.$store.dispatch('setAllTransportations', data.transportations);
			this.$store.dispatch('setAllIncidentTypes', data.incidentTypes);
			this.$store.dispatch('setAllDriverUsers', data.driverUsers);

			// w/ Billing Permission
			this.$store.dispatch('setAllClientAccounts', data.clientAccounts);

			// for Asset Accountability
			this.$store.dispatch('setAllCompanyAssetPools', data.companyAssetPools);
		},
		showValidationMsg(message, isValid) {
			if (isValid) {
				this.$toaster.success(message);
			} else {
				this.$toaster.warning(message);
			}

			// Hide loader
			this.isLoading = false;
		},
		async getDeployment() {
			await remoteConfig.fetchAndActivate();
			return remoteConfig.getString('deployment');
		},
	},
};
</script>

<style scoped>
.app {
	background-color: #F4E5D0;
}

.title {
	color: #F18F01;
	font-weight: bold;
}

.login {
	background-color: #F18F01;
	color: white;
	font-size: medium;
	border: none;
}

.img-logo {
	margin-top: 80px;
	margin-left: -40px;
	width: 65%;
}

.img-logo-small {
	width: inherit;
	text-align: center;
}

.ayun-logo-small {
	width: 70%;
	height: 70%;
}

.main-card {
	background-color: white !important;
	border-color: white;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.left-card {
	box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	border-right: none;
}

.right-card {
	box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	border-left: none;
}


.deployment {
	margin-top: 8px;
	color: #23282c;
}

.link {
	padding-left: 0px;
	color: #F18f01 !important;
	font-size: medium !important;
}

.icon {
	color: white;
}

.form-control {
	border-color: #f18f01;
}

.box {
	background-color: #F18f01;
	border-color: #f18f01;
}

.append-box {
	border: #f18f01;
	border-style: solid;
	border-width: thin;
}

.tagline {
	color: #F18F01;
	font-weight: bold;
	font-style: italic;
	position: absolute;
	font-size: large !important;
	bottom: 10%;
	left: 20%;
	transform: translateX(0%);
}

.circle {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 75%;
	background-color: #F4E5D0;
	border-top-left-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 95%;
	border: none;
}
.powered-by {
	color: #8D8D8D;
	text-align: center;
}
</style>
